import axios from 'axios'
import router from '@/router/index'
import { Modal, message } from 'ant-design-vue'
// import { message } from 'ant-design-vue';
// import { Notification, MessageBox } from 'element-ui'
// import store from '../store'
import { getToken, getTeam, removeToken } from '@/utils/auth'
import Config from '@/settings'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : '/', // api 的 base_url
  timeout: Config.timeout // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  config => {
    if (getToken()) {
      config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    if (getTeam()) {
      config.headers['team'] = getTeam() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    // debugger
    const code = response.status
    if (code < 200 || code > 300) {
      message.error(response.msg)
      return Promise.reject('error')
    } else {
      return response.data
    }
  },
  error => {
    let code = 0
    // debugger
    try {
      code = error.response.status
    } catch (e) {
      if (error.toString().indexOf('Error: timeout') !== -1) {
        message.error('网络请求超时')
        return Promise.reject(error)
      }
    }
    if (code) {
      // debugger
      if (code === 401) {
        Modal.confirm({
          title: '系统提示',
          content: '登录状态已过期，您可以继续留在该页面，或者重新登录',
          cancelText: '取消',
          okText: '确定',
          onOk() {
            Modal.destroyAll()
            removeToken()
            router.push({ path: '/sign-in' })
          }
        })
        // MessageBox.confirm(
        //   '登录状态已过期，您可以继续留在该页面，或者重新登录',
        //   '系统提示',
        //   {
        //     confirmButtonText: '重新登录',
        //     cancelButtonText: '取消',
        //     type: 'warning'
        //   }
        // ).then(() => {
        //   store.dispatch('LogOut').then(() => {
        //     location.reload() // 为了重新实例化vue-router对象 避免bug
        //   })
        // })
      } else if (code === 403) {
        router.push({ path: '/401' })
      } else {
        const errorMsg = error.response.data.msg
        // console.log(message, Message)
        if (errorMsg !== undefined) {
          message.error(errorMsg)
        }
      }
    } else {
      message.error('接口请求失败')
    }
    return Promise.reject(error)
  }
)
export default service
