import request from '@/utils/request'

export function loginAccount(data) {
  return request({
    url: '/api/login',
    method: 'post',
    data: data
  })
}
export function saveTmOrders(data) {
  return request({
    url: '/api/tm/orders',
    method: 'post',
    data: data
  })
}
export function updateTmOrders(data, orderId) {
  return request({
    url: `/api/tm/orders/${orderId}`,
    method: 'put',
    data: data
  })
}

export function getTmGoods(site, type) {
  // debugger
  return request({
    url: `/api/tm/goods?type=${type}&site=${site}`,
    method: 'get'
  })
}
export function getTmSite() {
  return request({
    url: '/api/tm/goods/attributes',
    method: 'get'
  })
}

export function getTmOrders(params) {
  return request({
    url: '/api/tm/orders',
    method: 'get',
    params
  })
}

export function updateOrderStatus(orderId, statusId) {
  return request({
    url: `/api/tm/orders/${orderId}/status/${statusId}`,
    method: 'patch'
  })
}

export function chart() {
  return request({
    url: 'api/data/chart',
    method: 'get'
  })
}

export function getOrderCount() {
  return request({
    url: 'api/yxStoreOrder/orderCount',
    method: 'get'
  })
}
